<template>
  <div class="jd-cash">
    <div v-if="!showDetail">
      <Head :head-info="HeadInfo" @headBack="goBack"></Head>
      <div class="jd-cash-head">
        <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/cash_jd_top.png" alt="">
      </div>
      <div class="goods-list">
        <template v-for="item in goodsList">
          <div :key="item.id" class="goods-wrap">
            <div class="info">
              <div class="goods-pic">
                <img :src="item.pictUrl">
              </div>
              <div class="goods-details">
                <p class="title">{{ item.itemTitle }}</p>
                <div class="goods-tips">
                  <p>京东自营</p>
                  <p>限量抢购</p>
                </div>
                <div class="original-price">
                  <p>京东价<span>¥ {{ item.reservePrice }}</span></p>
                </div>
                <div class="cash-price">
                  <p>补贴价 ¥<span>{{ item.zkFinalPrice }}</span></p>
                  <p>补贴{{ item.reservePrice - item.zkFinalPrice }}元</p>
                </div>
              </div>
            </div>
            <div class="footer-button">
              <p @click="handleJumpJd(item)">去京东查看商品
                <van-icon name="arrow"/>
              </p>
              <p @click="handleShowDetail(item)">补贴购买
                <van-icon name="arrow"/>
              </p>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-else class="detail-info">
      <div class="head-swipe">
        <van-swipe :autoplay="3000" v-if="goodsInfo.picList">
          <van-swipe-item v-for="(image, index) in goodsInfo.pictUrl" :key="index">
            <img v-lazy="image"/>
          </van-swipe-item>
        </van-swipe>
        <img class="goods-pic" v-else :src="goodsInfo.pictUrl" alt="">
        <img class="back" src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/cash_jd_detail_back.png" @click="back">
      </div>
      <div class="goods-base-info">
        <div class="cash-price">
          <p> ¥<span>{{ goodsInfo.zkFinalPrice }}</span>补贴价</p>
          <p>京东价<span>¥ {{ goodsInfo.reservePrice }}</span></p>
        </div>
        <div class="cash-desc">
          <p>鲸选师独家补贴特价，京东自营，售后无忧！</p>
        </div>
        <div class="goods-title">
          <span>京东</span>
          <span>{{ goodsInfo.itemTitle }}</span>
        </div>
        <div class="rate-volume">
          <p>
            好评率
            <span>{{ goodsInfo.goodRate }}%</span>
          </p>
          <p>
            30天销量
            <span>{{ goodsInfo.volume }}</span>
          </p>
        </div>
      </div>
      <div class="jd-sell">
        <p>京东自营</p>
        <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/cash_jd_desc.png" alt="">
      </div>
      <div class="jd-goods-img">
        <img :src="goodsInfo.pictUrl">
      </div>
      <div class="footer-button">
<!--        <div class="home-icon">-->
<!--          <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/cash_jd_home.png" alt="">-->
<!--          <p>首页</p>-->
<!--        </div>-->
        <div class="button" @click="handleShowSheet">
          <p>去下单</p>
        </div>
      </div>
    </div>
    <van-action-sheet v-model="showSheet" title="购买说明" :closeable="false">
      <div class="sheet-content">
        <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/cash_jd_buy_desc.png" alt="">
        <p class="service-wx">微信号: {{ wx }}</p>
        <p class="copy-button">
          <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_one_wx.png" alt="">
          <span v-clipboard:copy="wx" v-clipboard:success="onCopy" v-clipboard:error="onError">复制客服微信</span>
        </p>
        <p class="jd-buy" @click="handleJumpJd('')">去京东查看商品<span>（自己下单无补贴）</span></p>
        <p class="question-service">
          如有疑问请咨询：
          <span @click="handleContactUs">鲸选师客服</span>
        </p>
      </div>
    </van-action-sheet>
    <van-dialog v-model="dialogVisible" :showConfirmButton="false" style="background-color: transparent;">
      <div class="join-activity">
        <div class="inner">
          <div class="activity-info">
            <p class="activity-title">你还有1次免费领机会未使用</p>
            <p>好物免费领，0元包邮到家</p>
          </div>
          <div class="activity-pic">
            <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_dialog_zero.png"/>
          </div>
          <van-button @click="handlePay">现在就去领取</van-button>
          <p @click="handleCloseDialog">我不要了，残忍离开</p>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { ActionSheet, Icon, Button, Swipe, SwipeItem, Lazyload, Toast, Dialog } from 'vant'
import Head from '../../components/fullWebviewHead.vue'
import baseUrl from '@/utils/baseUrl'

const api = require('../../utils/api.js').api

Vue.use(ActionSheet)
Vue.use(Icon)
Vue.use(Button)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Dialog)
Vue.use(Toast)
Vue.use(Lazyload)

export default {
  name: 'jdCash',
  data () {
    return {
      //商品列表
      goodsList: [],
      goodsInfo: {},
      showDetail: false,
      page: 0,
      pageSize: 10,
      activityId: '',
      click: true,
      totalCount: 0,
      // 动作面板
      showSheet: false,
      dialogVisible: false,
      // 客服微信
      wx: 'hy2021778899',
      HeadInfo: {
        title: '超级秒杀',
        bgColor: '#EC2740',
        fontColor: '#FFFFFF',
      }
    }
  },
  components: {
    Head,
  },
  created () {
  },
  mounted () {
    window.addEventListener('scroll', this.lazyLoading)
    // 通过$on,$once使用hook监听vue生命周期函数，进行监听销毁
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('scroll', this.lazyLoading)
    })
    this.handleGetActivityList()
  },
  watch: {
    showDetail: (newValue, oldValue) => {
      let top = newValue == false ? window.localStorage.getItem('scrollTop') : 0
      window.scrollTo({
        top: top,
        behavior: 'smooth'
      })
    }
  },
  methods: {
    /*
  登录验证
   */
    isLogin () {
      let event = 'login'
      let param = {
        backUrl: `${baseUrl.pageUrl}/purchase`
      }
      this.$h5AppLink(event, param)
    },
    onCopy() {
      Toast.success('复制成功')
    },

    onError() {
      Toast.fail('复制失败')
    },

    /*
    图片下拉加载
    */
    lazyLoading () {
      // 获取 可视区高度`clientHeight、`滚动高度`scrollTop、`页面高度`scrollHeight
      let timer = null
      let scrollBox = ''
      let scrollTopGoods = document.documentElement.scrollTop || document.body.scrollTop
      let clientHeightGoods = document.documentElement.clientHeight
      let scrollHeightGoods = document.documentElement.scrollHeight
      if (scrollTopGoods + clientHeightGoods + 10 >= scrollHeightGoods) { // 滚动到底部，逻辑代码
        //事件处理
        if (this.goodsList.length < this.totalCount && this.totalCount !== 0) {
          this.handleGetActivityList('up')
        }
      }
    },
    /*
    活动商品列表
     */
    handleGetActivityList (type) {
      if (!this.click && type) {
        return false
      }
      this.page++
      this.click = false
      this.$axios(api.activityDetails, {
        params: {
          page: this.page,
          pageSize: this.pageSize,
          activityId: this.activityId || 264,
          itemSource: 2,
          clientType: 2
        }
      }).then(res => {
        this.goodsList = type == 'up' ? [...this.goodsList, ...res.data.data.data] : res.data.data.data
        this.totalCount = res.data.data.totalCount
        this.click = true
      }).catch(err => {
        console.info(err)
        this.isLogin()
      })
    },
    /*
    查看详情
     */
    handleShowDetail (item) {
      const height = document.documentElement.scrollTop || document.body.scrollTop
      window.localStorage.setItem('scrollTop', height)
      this.showDetail = true
      this.goodsInfo = item
    },
    /*
    头部返回按钮
     */
    back(){
      this.showDetail = false
    },
    /*
    下单弹出动作面板
     */
    handleShowSheet() {
      this.showSheet = true
    },
    /*
    联系我们
     */
    handleContactUs() {
      this.$h5AppLink('contactUs', {})
    },
    /*
    查看京东商品
     */
    handleJumpJd(item) {
      window.location.href = item ? item.itemUrl : this.goodsInfo.itemUrl
    },
    goBack() {
      this.$axios(api.drawPreBack, {}).then(res => {
        if (res.data.data.bizType == 2) {
          this.dialogVisible = true
        }
      }).catch(err => {
        console.info(err)
      })
    },
    /*
    0元购
     */
    handlePay() {
      this.$h5AppLink('openFreeActivity', {
        id: 2
      })
    },
    handleCloseDialog(){
      this.$router.go(-1)
    },
  }
}
</script>

<style lang="less" scoped>
.jd-cash {
  min-height: 100vh;
  background: #F4F4F4;

  .jd-cash-head {
    line-height: 0;

    & > img {
      width: 375px;
    }
  }

  .goods-list {
    position: relative;
    top: -22px;

    .goods-wrap {
      margin: 0 auto 16px;
      width: 360px;
      height: 176px;
      background: #FFFFFF;
      border-radius: 8px;

      .info {
        display: flex;
        margin: 0 8px;
        padding: 8px 0;

        .goods-pic {
          display: inline-block;

          & > img {
            width: 120px;
            height: 120px;
            border-radius: 10px;
          }
        }

        .goods-details {
          margin-left: 12px;

          .title {
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .goods-tips {
            display: flex;
            margin-top: 8px;

            & > p:first-child {
              text-align: center;
              width: 56px;
              height: 18px;
              background: #E44A59;
              border-radius: 2px;
              font-size: 12px;
              color: #FFFFFF;
              line-height: 17px;
            }

            & > p:last-child {
              margin-left: 8px;
              text-align: center;
              font-size: 12px;
              color: #333333;
              line-height: 17px;
              width: 56px;
              height: 18px;
              background: linear-gradient(90deg, #FCEBC0 0%, #F5B97C 100%);
              border-radius: 2px;
            }
          }

          .original-price {
            margin-top: 12px;
            display: flex;
            align-items: center;

            & > p {
              font-size: 12px;
              color: #999999;
              line-height: 17px;

              & > span {
                text-decoration: line-through;
              }
            }
          }

          .cash-price {
            display: flex;
            align-items: center;
            text-align: center;

            & > p:first-child {
              font-size: 12px;
              color: #EB3526;
              line-height: 17px;

              & > span {
                font-size: 24px;
                font-weight: bold;
                color: #EB3526;
                line-height: 33px;
              }
            }

            & > p:last-child {
              margin-left: 4px;
              min-width: 68px;
              height: 18px;
              border-radius: 9px;
              border: 1px solid #E44A59;
              font-size: 11px;
              color: #E44A59;
              line-height: 18px;
            }
          }
        }
      }

      .footer-button {
        display: flex;
        font-size: 15px;
        color: #FFFFFF;
        line-height: 40px;

        & > p:first-child {
          width: 180px;
          height: 40px;
          background: #333333;
          border-radius: 0px 0px 0px 8px;
        }

        & > p:last-child {
          width: 180px;
          height: 40px;
          background: #E44A59;
          border-radius: 0px 0px 8px 0px;
        }

        & > p {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .van-icon {
          margin-left: 10px;
        }
      }
    }
  }

  .detail-info {
    .head-swipe {
      position: relative;
      width: 375px;
      height: 380px;

      .goods-pic {
        width: 375px;
        height: 380px;
      }

      .back {
        position: absolute;
        top: 10px;
        left: 14px;
        width: 26px;
        height: 26px;
      }
    }

    .goods-base-info {
      padding: 3px 14px 7px;
      background: #FFFFFF;
      border-radius: 0px 0px 10px 10px;

      .cash-price {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        text-align: center;

        & > p:first-child {
          font-size: 12px;
          color: #EB3526;
          line-height: 17px;

          & > span {
            font-size: 24px;
            font-weight: bold;
            color: #EB3526;
            line-height: 33px;
          }
        }

        & > p:last-child {
          font-size: 14px;
          color: #999999;
          line-height: 26px;

          & > span {
            margin-left: 2px;
            text-decoration: line-through;
          }
        }
      }

      .cash-desc {
        margin-top: 6px;
        margin-bottom: 12px;
        width: 345px;
        height: 30px;
        background: #E44A59;
        border-radius: 2px;

        & > p {
          margin-left: 10px;
          font-size: 13px;
          color: #FFFFFF;
          line-height: 30px;
        }
      }

      .goods-title {
        font-size: 16px;
        color: #333333;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;

        & > span:first-child {
          position: relative;
          top: -2px;
          font-size: 8px;
          color: #fff;
          margin-top: -2px;
          margin-right: 7px;
          border-radius: 2px;
          padding: 1px 4px;
          background-color: #e44a59;
        }

        & > span:last-child {
          font-weight: 600;
        }
      }

      .rate-volume {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > p {
          font-size: 12px;
          color: #999999;
          line-height: 17px;

          & > span {
            margin-left: 4px;
            font-size: 15px;
            color: #333333;
            line-height: 21px;
          }
        }
      }
    }

    .jd-sell{
      margin-top: 8px;
      padding: 10px 20px;
      background: #FFFFFF;
      border-radius: 10px;
      & > p{
        font-size: 15px;
        font-weight: bold;
        color: #000000;
        line-height: 21px;
      }
      & > img{
        margin-top: 10px;
        width: 322px;
      }
    }
    .jd-goods-img{
      margin-top: 8px;
      line-height: 0;
      & > img{
        width: 375px;
      }
    }
    .footer-button{
      position: fixed;
      bottom: 0;
      display: flex;
      align-items: center;
      text-align: center;
      width: 375px;
      height: 49px;
      background: #FFFFFF;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
      .home-icon{
        width: 70px;
        & > img{
          width: 24px;
        }
        & > p{
          font-size: 12px;
          color: #B6B6B6;
          line-height: 17px;
        }
      }
      .button{
        width: 100%;
        height: 100%;
        background: #FFEA1C;
        & > p{
          font-size: 16px;
          font-weight: 600;
          color: #333333;
          line-height: 49px;
        }
      }
    }
  }
  .sheet-content{
    padding: 16px;
    & > img{
      width: 340px;
      height: 232px;
    }
    .service-wx{
      margin-top: 14px;
      text-align: center;
      font-size: 13px;
      font-weight: 500;
      color: #E44A59;
      line-height: 18px;
    }
    .copy-button{
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 320px;
      height: 50px;
      background: #FFEA1C;
      box-shadow: 0px 3px 5px 1px rgba(255, 234, 28, 0.5);
      border-radius: 25px;
      & > img{
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      & > span{
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 22px;
      }
    }
    .jd-buy{
      margin-top: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 320px;
      height: 50px;
      border-radius: 25px;
      border: 1px solid #999999;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 22px;
      & > span{
        font-size: 12px;
        line-height: 17px;
      }
    }
    .question-service{
      margin-top: 20px;
      text-align: center;
      font-size: 12px;
      color: #999999;
      line-height: 17px;
      & > span{
        color: #C99040;
      }
    }
  }
  .join-activity {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    //width: 320px;
    //height: 420px;
    background: linear-gradient(180deg, #F5C600 0%, #F4823B 100%);
    border-radius: 16px;
    text-align: center;

    .inner {
      width: 306px;
      //height: 406px;
      background: #FCF2D6;
      border-radius: 16px;

      .van-button {
        margin-bottom: 14px;
        width: 260px;
        height: 50px;
        background: linear-gradient(180deg, #F74545 0%, #D92635 100%);
        box-shadow: 0px 2px 6px 0px #D41020;
        border-radius: 28px;
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 28px;
        border: none;
      }

      & > p {
        margin-bottom: 10px;
      }
    }

    .small-inner {
      height: 190px;

      .van-button {
        margin-top: 21px;
      }
    }

    p {
      font-size: 15px;
      font-weight: 400;
      color: #C99040;
      line-height: 21px;
    }

    .activity-info {
      .activity-title {
        margin-top: 16px;
        margin-bottom: 6px;
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
      }
    }

    .activity-pic {
      margin: 18px auto;

      img {
        width: 180px;
        height: 200px;
      }
    }

    .award-pic {
      margin: 10px auto 4px;

      img {
        width: 198px;
        height: 198px;
      }
    }

    .privilege-open {
      .title {
        margin-top: 16px;
        font-size: 20px;
        font-weight: bold;
        color: #C99040;
        line-height: 28px;
      }

      .icon-list {
        margin: 16px 24px;
        display: flex;
        justify-content: space-between;

        & > div {
          & > img {
            width: 70px;
            height: 70px;
          }

          & > p {
            font-size: 13px;
            color: #C99040;
            line-height: 18px;
          }
        }
      }

      .van-button {
        margin-bottom: 16px;
        width: 260px;
        height: 50px;
        background: linear-gradient(180deg, #F74545 0%, #D92635 100%);
        box-shadow: 0px 2px 8px 0px #FE8D62;
        border-radius: 28px;
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 50px;
        border: none;
      }

      & > p {
        margin-bottom: 16px;
        font-size: 15px;
        color: #C99040;
        line-height: 21px;
      }
    }

    .service-time {
      margin-bottom: 12px;
      font-size: 15px;
      color: #C99040;
      line-height: 21px;
    }
  }
}
</style>
